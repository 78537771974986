export const columnsDataWaitingLoan = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "credit_policy",
    accessor: "credit_policy",
  },
  {
    Header: "purpose",
    accessor: "purpose",
  },
  {
    Header: "int_rate",
    accessor: "int_rate",
  },
  {
    Header: "installment",
    accessor: "installment",
  },
  // {
  //   Header: "log_annual_inc",
  //   accessor: "log_annual_inc",
  // },
  // {
  //   Header: "dti",
  //   accessor: "dti",
  // },
  // {
  //   Header: "fico",
  //   accessor: "fico",
  // },
  // {
  //   Header: "days_with_cr_line",
  //   accessor: "days_with_cr_line",
  // },
  // {
  //   Header: "revol_bal",
  //   accessor: "revol_bal",
  // },
  // {
  //   Header: "revol_util",
  //   accessor: "revol_util",
  // },
  // {
  //   Header: "inq_last_6mths",
  //   accessor: "inq_last_6mths",
  // },
  // {
  //   Header: "delinq_2yrs",
  //   accessor: "delinq_2yrs",
  // },
  // {
  //   Header: "pub_rec",
  //   accessor: "pub_rec",
  // },
  {
    Header: "predict_lr",
    accessor: "predict_lr",
  },
  {
    Header: "predict_rf",
    accessor: "predict_rf",
  },
];
